/* You can add global styles to this file, and also import other style files */
:root {
  //BLUE
  --main-blue-light: #0DCCFF;
  --main-blue-medium: #0B84FF;
  --main-blue-dark: #054280;
  --main-blue-semiTrns: #D1F5FF;
  --main-blue-gradient-light: linear-gradient(64.8deg, var(--main-blue-semiTrns) 0.87%, var(--main-blue-light) 95.4%);
  --main-blue-gradient-medium: linear-gradient(64.48deg, var(--main-blue-medium) 0.87%, var(--main-blue-light) 96.28%);
  --main-blue-gradient-medium-reverse: linear-gradient(267.38deg, var(--main-blue-medium) 0.87%, var(--main-blue-light) 96.28%);
  --main-blue-gradient-dark: linear-gradient(64.64deg, var(--main-blue-medium) 0.87%, var(--main-blue-dark) 96.11%);
  --main-blue-tamed-dark: rgba(5, 66, 128, 0.05);
  //MAGENTA
  --main-magenta-light: #E31482;
  --main-magenta-medium: #B01065;
  --main-magenta-dark: #66006A;
  --main-magenta-semiTrns: #FFDDEF;
  --main-magenta-gradient-light: linear-gradient(66.92deg, #FFDDEF 1.11%, var(--main-magenta-light) 99.05%);
  --main-magenta-gradient-medium: linear-gradient(64.32deg, var(--main-magenta-medium) 0.87%, var(--main-magenta-dark) 95.39%);
  --main-magenta-gradient-medium-reverse: linear-gradient(267.38deg, var(--main-magenta-medium) 0.87%, var(--main-magenta-dark) 95.39%);
  --main-magenta-gradient-dark: linear-gradient(64.07deg, var(--main-magenta-light) 2.15%, var(--main-magenta-dark) 96.63%);
  //GREEN
  --main-green-light: #00FFAA;
  --main-green-medium: #00B377;
  --main-green-dark: #00593B;
  --main-green-semiTrns: #C7FDEB;
  --main-green-gradient-light: linear-gradient(66.92deg, var(--main-green-semiTrns) 1.11%, var(--main-green-light) 99.05%);
  --main-green-gradient-medium: linear-gradient(64.32deg, var(--main-green-medium) 0.87%, var(--main-green-light) 95.39%);
  --main-green-gradient-medium-reverse: linear-gradient(267.38deg, var(--main-green-medium) 0.87%, var(--main-green-light) 95.39%);
  --main-green-gradient-dark: linear-gradient(64.07deg, var(--main-green-medium) 2.15%, var(--main-green-dark) 96.63%);
  //ORANGE
  --main-orange: #FF830D;
  //RED
  --main-red: #E22B2B;
  //DENIM
  --main-denim-light: #7797B8;
  --main-denim-medium: #284767;
  --main-denim-dark: #061E37;
  --main-denim-semiTrns: #C4D9EE;
  --main-denim-gradient-medium-reverse: linear-gradient(267.38deg, var(--main-denim-dark) -8.78%, var(--main-denim-medium) 95.66%);

}

@function movanoResize($pixels) {
  @return max(calc(#{$pixels} * (100vw/1440)), #{$pixels}px);
}

body {
  margin: 0;
  font-family: 'Zen Kaku Gothic Antique', sans-serif;
  color: var(--main-blue-dark);
  overflow-x: hidden;
}
/* Estilo para ocultar la barra de desplazamiento */
body::-webkit-scrollbar {
  width: movanoResize(3); /* Ancho de 2px */
  height: movanoResize(3); /* Altura de 45px */
  margin: movanoResize(5);
}

/* Estilo para ocultar el pulgar de la barra de desplazamiento */
body::-webkit-scrollbar-thumb {
  background-color: #C4D9EE; /* Color de fondo #C4D9EE */
  margin: movanoResize(5);
}

/* Estilo para ocultar las flechas de la barra de desplazamiento */
body::-webkit-scrollbar-button {
  display: none; /* Ocultar las flechas de la barra de desplazamiento */
}
/* Estilo para ocultar la barra de desplazamiento */
div::-webkit-scrollbar {
  width: movanoResize(3); /* Ancho de 2px */
  height: movanoResize(3); /* Altura de 45px */
  margin: movanoResize(5);
}

/* Estilo para ocultar el pulgar de la barra de desplazamiento */
div::-webkit-scrollbar-thumb {
  background-color: #C4D9EE; /* Color de fondo #C4D9EE */
  margin: movanoResize(5);
}

/* Estilo para ocultar las flechas de la barra de desplazamiento */
div::-webkit-scrollbar-button {
  display: none; /* Ocultar las flechas de la barra de desplazamiento */
}
h1 {
  font-size: movanoResize(40);
  font-weight: 300;
  line-height: movanoResize(53);
  letter-spacing: 0px;
  text-align: left;
  font-style: normal;
}

h2 {
  font-size: movanoResize(35);
  font-weight: 300;
  line-height: movanoResize(47);
  letter-spacing: 0px;
  text-align: left;
  font-style: normal;
}

h3 {
  font-size: movanoResize(30);
  font-weight: 400;
  line-height: movanoResize(40);
  letter-spacing: 0px;
  text-align: left;
  font-style: normal;
}

h4 {
  font-size: movanoResize(25);
  font-weight: 400;
  line-height: movanoResize(33);
  letter-spacing: 0px;
  text-align: left;
  font-style: normal;
}

h5 {
  font-size: movanoResize(20);
  font-weight: 400;
  line-height: movanoResize(27);
  letter-spacing: 0px;
  text-align: left;
  font-style: normal;
}

h6 {
  font-size: movanoResize(20);
  font-weight: 400;
  line-height: movanoResize(27);
  letter-spacing: 0px;
  text-align: left;
  font-style: normal;
}

.subtitle1 {
  font-size: movanoResize(16);
  font-weight: 400;
  line-height: movanoResize(298);
  letter-spacing: 0px;
  text-align: left;
}

.body1 {
  font-size: movanoResize(14);
  font-weight: 400;
  line-height: movanoResize(19);
  letter-spacing: 0px;
  text-align: left;
}

.body2 {
  font-size: movanoResize(146);
  font-weight: 500;
  line-height: movanoResize(16);
  letter-spacing: 0px;
  text-align: left;
}

.alternative1 {
  font-size: movanoResize(10);
  font-weight: 500;
  line-height: movanoResize(13);
  letter-spacing: 0px;
  text-align: left;
}

.alternative2 {
  font-size: movanoResize(8);
  font-weight: 700;
  line-height: movanoResize(145);
  letter-spacing: 0px;
  text-align: left;
}

a {
  font-weight: 700;
  font-size: movanoResize(12);
  line-height: movanoResize(12);
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: var(--main-blue-medium);
}

a:hover {
  text-shadow: 0px movanoResize(5) movanoResize(10) rgba(5, 66, 128, 0.3);
  cursor: pointer;
}

a:active {
  color: var(--main-blue-dark);
}

.movano_checkBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  label {
    color: var(--main-blue-dark);
  }

  mat-checkbox {
    transform: scale(0.85);
  }

  .movanoTable_Obj_Badge {
    min-width: movanoResize(18);
    height: movanoResize(18);
  }
}

.custom-spinner .p-progress-spinner-circle {
  animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;
}

.custom-spinner-blue .p-progress-spinner-circle {
  animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color-blue 6s ease-in-out infinite;
}

.movanoUsers {
  .p-progress-spinner {
    width: movanoResize(50) !important;
    height: movanoResize(50) !important;
  }
}

@keyframes custom-progress-spinner-color {

  100%,
  0% {
    stroke: #FFFFFF;
  }

  40% {
    stroke: #FFFFFF;
  }

  66% {
    stroke: #FFFFFF;
  }

  80%,
  90% {
    stroke: #FFFFFF;
  }
}

@keyframes custom-progress-spinner-color-blue {

  100%,
  0% {
    stroke: var(--main-blue-dark);
  }

  40% {
    stroke: var(--main-blue-dark);
  }

  66% {
    stroke: var(--main-blue-dark);
  }

  80%,
  90% {
    stroke: var(--main-blue-dark);
  }
}

@keyframes custom-progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: movanoResize(-35);
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: movanoResize(-124);
  }
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: black !important;
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  box-shadow: 0px 0px movanoResize(5) rgba(0, 56, 108, 0.1);
  border-radius: movanoResize(2);
}

.mat-mdc-snack-bar-container {
  color: var(--main-blue-medium) !important;
}

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: var(--main-blue-medium);
  --mdc-checkbox-selected-hover-icon-color: var(--main-blue-medium);
  --mdc-checkbox-selected-icon-color: var(--main-blue-medium);
  --mdc-checkbox-selected-pressed-icon-color: var(--main-blue-medium);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}

.mdc-checkbox__ripple {
  display: none;
}

.mat-ripple {
  display: none;
}

.mdc-checkbox__background {
  box-shadow: 0px 0px movanoResize(5) rgba(0, 56, 108, 0.1);
  border: movanoResize(1) solid var(--main-denim-semiTrns) !important;
  //border-radius: 9px !important;
}

*:disabled {
  opacity: 0.5;
}

.mat-mdc-progress-spinner circle {
  stroke: #ffffff !important;
}

.movano_switch_disabled {
  .slider {
    background: var(--main-denim-semiTrns) !important;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked+.slider {
    background: var(--main-denim-semiTrns);
  }
}

//SWITCH
.movano_switchLine {
  .switch {
    position: relative;
    display: inline-block;
    width: movanoResize(25);
    height: movanoResize(1);
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--main-blue-gradient-medium-reverse);
    -webkit-transition: .4s;
    transition: .4s;
    font-weight: 400;
    font-size: movanoResize(145);
    line-height: movanoResize(145);
    display: flex;
    align-items: center;
    color: var(--main-blue-dark);

    .sliderText {
      margin-left: movanoResize(30);
      margin-bottom: movanoResize(4);
    }
  }

  .slider:before {
    position: absolute;
    content: "";
    height: movanoResize(145);
    width: movanoResize(145);
    left: movanoResize(-2);
    top: movanoResize(-5);
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    box-shadow: 0px movanoResize(1) movanoResize(15) rgba(0, 56, 108, 0.25);
  }

  input:checked+.slider {
    background: var(--main-blue-gradient-medium-reverse);
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(movanoResize(17));
    -ms-transform: translateX(movanoResize(17));
    transform: translateX(movanoResize(17));
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: movanoResize(34);
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.p-progressbar{
  border-radius: movanoResize(4);
}
.p-progressbar-value{
  border-radius: movanoResize(4);
}

.progessBarMovano {
  position: relative;
  span {
    color: white;
    font-weight: 700;
    font-size: movanoResize(14);
    position: absolute;
    left: 25.5vw;
    z-index: 99;
    display: flex;
    justify-content: center;
    height: 3vw;
    align-items: center;
    width: 30vw;
  }
}
mat-icon {
  //padding: 0px !important;
  font-size: movanoResize(16) !important;
  width: movanoResize(16) !important;
  height: movanoResize(16) !important;
}

.movano_switch {
  .switch {
    position: relative;
    display: inline-block;
    width: movanoResize(25);
    height: movanoResize(11);
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--main-blue-dark);
    -webkit-transition: .4s;
    transition: .4s;
    font-weight: 400;
    font-size: movanoResize(12);

    line-height: movanoResize(12);
    display: flex;
    align-items: center;
    color: var(--main-blue-dark);

    .sliderText {
      margin-left: movanoResize(30);
      margin-bottom: movanoResize(4);
    }
  }

  .slider:before {
    position: absolute;
    content: "";
    height: movanoResize(7);
    width: movanoResize(7);
    left: movanoResize(4);
    bottom: movanoResize(2);
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked+.slider {
    background: var(--main-blue-gradient-medium-reverse);
  }

  input:focus+.slider {
    box-shadow: 0 0 movanoResize(1) #2196F3;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(movanoResize(11));
    -ms-transform: translateX(movanoResize(11));
    transform: translateX(movanoResize(11));
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: movanoResize(34);
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.movano_switchTimeZone {
  .switch {
    position: relative;
    display: inline-block;
    width: movanoResize(85);
    height: movanoResize(25);
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(5, 66, 128, 0.1);
    -webkit-transition: .4s;
    transition: .4s;
    font-weight: 400;
    font-size: movanoResize(12);
    line-height: movanoResize(12);
    display: flex;
    align-items: center;
    z-index: 1;
    color: var(--main-blue-dark);
    display: flex;
    justify-content: space-around;

    .sliderText {
      z-index: 10;
      font-weight: 400;
      color: rgba(5, 66, 128, 0.5);
      font-size: movanoResize(12);
      // margin-left: movanoResize(30);
      // margin-bottom: movanoResize(4);
    }

    .sliderText.selected {
      color: #FFFFFF;
      font-weight: 700;
    }
  }

  .slider:before {
    position: absolute;
    content: "";
    height: movanoResize(25);
    width: movanoResize(50);
    left: movanoResize(0);
    -webkit-transform: translateX(movanoResize(45));
    -ms-transform: translateX(movanoResize(45));
    transform: translateX(movanoResize(45));
    background: var(--main-blue-gradient-medium-reverse);
    -webkit-transition: .4s;
    transition: .4s;
    z-index: 3;
    box-shadow: 0px movanoResize(3) movanoResize(10) rgba(0, 56, 108, 0.2);
  }

  input:checked+.slider {
    background-color: rgba(5, 66, 128, 0.1);
  }

  input:focus+.slider {
    box-shadow: 0 0 movanoResize(1) #2196F3;
  }

  input:checked+.slider:before {
    width: movanoResize(43);
    -webkit-transform: translateX(movanoResize(0));
    -ms-transform: translateX(movanoResize(0));
    transform: translateX(movanoResize(0));
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: movanoResize(34);
  }

  .slider.round:before {
    border-radius: movanoResize(12);
  }
}
.movano-dropdown{
  position: relative;
  width: movanoResize(215);
  svg{
    position: absolute;
    top: calc(50% - movanoResize(3));
    right: movanoResize(4);
    width: movanoResize(8);
    height: movanoResize(6);
  }
}
.p-dropdown{
  border: unset;
  width: movanoResize(215);
  color: var(--main-blue-dark);
  font-size: movanoResize(12);
  box-shadow: unset;

  &-label.p-placeholder{
    line-height: 100%;
    color: var(--main-blue-dark) !important;
    font-size: movanoResize(12) !important;
    font-weight: 500 !important;
    box-shadow: unset;
    padding-left: movanoResize(15) !important;
  }
  &-panel &-items{
    padding-top: movanoResize(5);
    padding-bottom: movanoResize(5);
  }
  &-panel &-items &-item{
    padding: movanoResize(5);
    padding-left: movanoResize(30);
    color: var(--main-blue-dark);
    font-size: movanoResize(12);
    font-weight: 500;
  }
  &-label{
    color: var(--main-blue-dark);
    font-size: movanoResize(12);
    font-weight: 500;
  }
  &-trigger{
    display: none !important;
  }
  &-panel{
    position: relative;
    bottom: movanoResize(37);
    border: movanoResize(1) solid var(--main-blue-medium);
    border-radius: movanoResize(2);
    color: var(--main-blue-dark);
    font-size: movanoResize(12);
    font-weight: 500;
  }
  &-item{
    font-weight: 500;
  }
}
.p-dropdown:hover{
  border: unset !important;
}
// .movano-select{
//   border: unset;
//   width: movanoResize(220);
//   color: var(--main-blue-dark);
//   font-size: movanoResize(12);
//   font-weight: 500;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   option{
//     padding: movanoResize(20);
//     border-color: var(--main-blue-dark);
//     color: var(--main-blue-dark);
//     font-size: movanoResize(12);
//     font-weight: 500;
//     border-radius: movanoResize(2);
//   }
// }

.mat-mdc-select-panel {
  border-radius: 0px !important;
  border: movanoResize(1) solid var(--main-blue-medium);
  border-top-style: none;
  position: relative !important;
  top: movanoResize(-5) !important;
  border-top: 0px;
}

.UtcList {
  border-radius: movanoResize(2) !important;
  border: movanoResize(1) solid var(--main-blue-dark) !important;
  position: relative !important;
  bottom: movanoResize(25) !important;
  top: 0px !important;
  border-top: movanoResize(1) solid var(--main-blue-dark) !important;
}

mat-select {
  height: movanoResize(37) !important;
}

p-calendar {
  height: movanoResize(37) !important;
}

.p-datepicker-other-month {
  opacity: 0;
}

.p-datepicker {
  border-radius: 0vw 0vw movanoResize(1) movanoResize(1) !important;
  border-left: movanoResize(1) solid var(--main-denim-semiTrns) !important;
  border-right: movanoResize(1) solid var(--main-denim-semiTrns) !important;
  border-bottom: movanoResize(1) solid var(--main-denim-semiTrns) !important;

  th {
    span {
      width: fit-content !important;
      font-family: 'Zen Kaku Gothic Antique';
      font-weight: 500;
      font-size: movanoResize(10);
      color: #C3D7ED;
    }
  }

  td:has(span.p-highlight):has(div.selectedDate) {
    background: var(--main-blue-medium) !important;
    border-radius: movanoResize(2) !important;

    span {
      color: white !important;
    }
  }

  td:has(span.p-highlight):has(div.interDate) {
    background: var(--main-blue-semiTrns) !important;
    border-radius: 0px !important;

    span {
      color: var(--main-blue-dark) !important;
    }
  }


  td:has(> span.p-highlight) {
    background: var(--main-blue-medium) !important;
    border-radius: movanoResize(2) !important;

    span {
      color: white !important;
    }
  }

  td:has(div.weekSelect) {
    background: var(--main-blue-medium) !important;
    border-radius: 0px !important;

    span {
      color: white !important;
    }
  }

  td:has(div.weekSelect):first-child {
    border-radius: movanoResize(2) 0px 0px movanoResize(2) !important;
  }

  td:has(div.weekSelect):last-child {
    border-radius: 0px  movanoResize(2) movanoResize(2) 0px !important;
  }

  td {
    padding: 0px !important;

    .p-highlight {
      background: rgba(0, 0, 0, 0) !important;
      border-radius: 0% !important;
    }

    span {
      width: movanoResize(28) !important;
      height: movanoResize(28) !important;
      font-family: 'Zen Kaku Gothic Antique' !important;
      font-weight: 400 !important;
      font-size: movanoResize(12) !important;
      color: var(--main-blue-dark) !important;
      border-radius: movanoResize(2) !important;
    }
  }
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  font-family: 'Zen Kaku Gothic Antique';
  font-weight: 700;
  font-size: movanoResize(14);
  color: var(--main-blue-medium);
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year {
  font-family: 'Zen Kaku Gothic Antique';
  font-weight: 700;
  font-size: movanoResize(14);
  color: var(--main-blue-medium);
}
.inside-calendar{

  .p-calendar {
    width: 100%;
  }
  .p-datepicker{
    border: unset !important;
    &-header{
      padding-right: 2rem;
      min-width: max-content;
    }
  }

  &_CloseSVG{
    width: movanoResize(12);
    height: movanoResize(12);
    position: absolute;
    right: movanoResize(12);
    top: 1.7rem;
  }
  &_CloseSVG:hover{
    cursor: pointer;
  }
}
.cdk-overlay-pane {
  max-width: 95vw !important;
  overflow-y: auto;
  //width: 90vw !important;
}
.cdk-overlay-pane:has(.UtcList){
  margin-top: calc(movanoResize(37) * -1);
}

.movanoUserPanel {
  background-color: white;
  //width: 80vw;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  &_Header_Tittle {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding-top: movanoResize(20);
      padding-bottom: movanoResize(40);

      span {
        color: var(--main-blue-dark);
        font-weight: 700;
        font-size: movanoResize(22);
      }
      svg {
        width: movanoResize(12);
        width: movanoResize(12);
        position: absolute;
        right: movanoResize(35);
      }

  }
}

.movanoDark {
  .movanoUserPanel {

    &_Header {
      &_SubTittle_Measures_Label {
        background: rgba(255, 255, 255, 0.1);
        color: white;
      }

      &_Tittle {
        span {
          color: var(--main-blue-medium);
        }
      }
    }

    &_Legend {
      background: #05305B;

      &_Elements:last-child {
        svg>circle {
          stroke: white !important;
          fill: transparent !important;
        }

        span {
          color: white !important;
        }
      }
    }

    &_YAxys_Mark {
      //color: var(--main-blue-semiTrns) !important;
    }
  }
}
.movanoUserPanel_Header_SubTittle_BatteryComboBox {
    width: movanoResize(115) !important;

    mat-icon {
      display: none;
    }
    .mat-mdc-select-placeholder {
      margin-left: 0px !important;
      color: var(--main-blue-dark) !important;
      font-weight: 500 !important;
    }

    .mat-mdc-text-field-wrapper {
      border: none;
      box-shadow: none;
      background: none !important;
    }

    .mat-mdc-form-field-focus-overlay {
      background: rgba(0, 0, 0, 0) !important;
    }
    .mat-mdc-form-field-subscript-wrapper{
      display: none;
    }
      .mat-mdc-option {
    span {
      font-size: movanoResize(11) !important;
    }
  }

  .mat-mdc-form-field-infix {
    span {
      margin-left: 0px;
    }
  }
  .mdc-text-field{
    padding-left: movanoResize(5) !important;
  }
}

.mat-mdc-form-field-infix {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  width: movanoResize(173) !important;

  .mat-mdc-select-trigger {
    display: block;
  }

  .mat-mdc-select-value {
    height: movanoResize(37) !important;
  }

  span {
    font-weight: 400;
    line-height: movanoResize(37);
    font-size: movanoResize(10) !important;
    margin-left: movanoResize(25);
    color: var(--main-blue-dark) !important;
    position: relative;
    display: block;
    height: movanoResize(37) !important;
  }
}
.movanoFilterMenu_Filter{
  .mat-mdc-form-field-infix span {
    margin-left: unset !important;
  }
}
.mat-mdc-select-placeholder {
  margin-left: movanoResize(30) !important;
  font-weight: 400 !important;
  font-size: movanoResize(10) !important;
  opacity: 0.5 !important;
  line-height: movanoResize(24);
  color: rgba(5, 66, 128, 0.5) !important;
}

.mat-mdc-select-arrow-wrapper {
  display: none !important;
}

.mat-mdc-text-field-wrapper {
  border-radius: movanoResize(2) !important;
  border: movanoResize(1) solid var(--main-denim-semiTrns);
  background: #FFF;
  height: movanoResize(37) !important;

  box-shadow: 0px movanoResize(1) movanoResize(15) rgba(0, 56, 108, 0.1);

  svg {
    transform: rotate(-0deg) !important;
    transform: rotate(0deg);
    position: absolute;
    bottom: 42%;
    right: 0.84vw;
    width: movanoResize(8);
    height: movanoResize(6);
  }

  path {
    fill: var(--main-denim-medium) !important;
  }
}

.mdc-line-ripple {
  display: none !important;
}

.mat-mdc-text-field-wrapper:hover {
  border-radius: movanoResize(2) !important;
  border: movanoResize(1) solid var(--main-blue-medium);
  height: movanoResize(37) !important;
  background: #FFF;
}

.mdc-text-field--focused {
  border: movanoResize(1) solid var(--main-blue-medium) !important;
  border-radius: movanoResize(2) !important;
  height: movanoResize(37) !important;

  svg {
    transform: rotate(-180deg) !important;
  }

  path {
    fill: #AABED3 !important;
  }
}

.mat-mdc-form-field-focus-overlay {
  opacity: 1 !important;
  background-color: white !important;
}


.mdc-menu-surface {
  box-shadow: 0px movanoResize(8) movanoResize(11) movanoResize(1) rgb(0 0 0 / 14%) !important;
}

mat-form-field {
  border: none !important;
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-start {
  border-radius: 0px !important;
  border: movanoResize(1) solid red !important;
  border-right-style: none !important;
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-end {
  border-radius: 0px !important;
  border: movanoResize(1) solid red !important;
  border-left-style: none !important;
}

.mat-mdc-select-panel ng-animating {
  visibility: hidden;
}

.mat-mdc-option {
  min-height: movanoResize(37) !important;
  height: movanoResize(37) !important;

  span {
    font-weight: 500 !important;
    font-size: movanoResize(12) !important;
    color: var(--main-blue-dark) !important;
  }
}
.movano-select_fakeOption {
  padding: 0px 16px 0px 16px;
  min-height: movanoResize(37);
  height: movanoResize(37);
  font-weight: 500;
  font-size: movanoResize(12);
  color: var(--main-blue-dark);
  line-height: 100%;
  display: flex;
  align-items: center;
}
.movano-select_fakeOption:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}
.p-inputtext:focus {
  border: movanoResize(1) solid var(--main-blue-medium) !important;
}

.movanoUserPanel {
  .p-inputtext {
    width: movanoResize(250) !important;
  }
}

.p-inputtext {
  width: movanoResize(250) !important;
  height: movanoResize(37) !important;
  border-radius: movanoResize(2);
  box-shadow: 0px 0px movanoResize(10) rgba(0, 56, 108, 0.3);
  border: movanoResize(1) solid var(--main-denim-semiTrns);
  background: #FFF;
  font-size: movanoResize(10);
  padding-left: movanoResize(36) !important;
  padding-right: movanoResize(36) !important;
  box-shadow: 0px movanoResize(4) movanoResize(12) rgba(0, 56, 108, 0.1);
  border-radius: movanoResize(2);
  border: movanoResize(1) solid var(--main-denim-semiTrns);
  font-weight: 500;
  font-size: movanoResize(12);
  font-family: 'Zen Kaku Gothic Antique';
  color: rgba(5, 66, 128, 1);

}

.p-calendar .p-inputtext {
  z-index: 10 !important;
  background: transparent;
}

.p-inputtext:hover {
  cursor: pointer !important;
}


.p-inputtext:hover {
  border: movanoResize(1) solid var(--main-blue-medium) !important;
}

.p-inputtext:focus,
textarea:focus {
  outline: none;
  border: movanoResize(1) solid var(--main-blue-medium) !important;
}

.p-inputtext:disabled {
  background: var(--main-denim-semiTrns) !important;
  box-shadow: none !important;
  color: var(--main-denim-light);
  border: none !important;
}

input:focus {
  border: movanoResize(1) solid var(--main-blue-medium) !important;
}

input {
  width: movanoResize(250);
  height: movanoResize(37);
  border-radius: movanoResize(2);
  box-shadow: 0px 0px movanoResize(10) rgba(0, 56, 108, 0.3);
  border: movanoResize(1) solid var(--main-denim-semiTrns);
  background: #FFF;
  font-weight: 500;
  font-size: movanoResize(12);
  box-sizing: border-box;
  font-weight: 400;
  font-size: movanoResize(10);
  padding-left: movanoResize(16);
}

input:hover {
  border: movanoResize(1) solid var(--main-blue-medium) !important;
}

input:focus,
textarea:focus {
  outline: none;
  border: movanoResize(1) solid var(--main-blue-medium) !important;
}

.errorInput {
  border: movanoResize(1) solid var(--main-red) !important;
}

input:disabled {
  background: var(--main-denim-semiTrns) !important;
  box-shadow: none !important;
  color: var(--main-denim-light);
  border: none !important;
}

.cdk-overlay-dark-backdrop {
  opacity: 0.8 !important;
  background: linear-gradient(109.51deg, var(--main-blue-medium) 2.09%, var(--main-blue-dark) 99.08%);
}
.cdk-overlay-container:has(.movanoDark)
{
  .cdk-overlay-dark-backdrop {
    background: linear-gradient(135deg, var(--main-blue-dark) 0%, var(--main-denim-dark) 100%);
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(5, 66, 128, 0.5);
  /* Firefox */
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(5, 66, 128, 0.5);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(5, 66, 128, 0.5);
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: movanoResize(8) !important;
  background: none !important;
  box-shadow: none !important;
}

.mdc-dialog--open .mdc-dialog__scrim {

  background: none !important;
}

.mdc-dialog--open .mdc-dialog__scrim {
  opacity: 0;
}

mat-mdc-dialog-container {
  background: none !important;
}

p-chart {
  z-index: 5;

  div {
    border: none !important;
    background: none !important;
  }

  .chartToolTipDiv {
    font-family: 'Zen Kaku Gothic Antique', sans-serif !important;
    box-shadow: 0px 0px movanoResize(20) rgba(5, 66, 128, 0.15) !important;
    // background: none !important;
    font-style: normal;
    font-weight: 700;
    font-size: movanoResize(20);
    border-radius: movanoResize(5) !important;
    border: none !important;
    height: movanoResize(73);
    z-index: 99;

    tbody {
      position: relative;
      display: flex !important;
      flex-direction: column !important;

      tr {
        width: movanoResize(35) !important;
        height: movanoResize(40);
        margin-right: movanoResize(5);
        position: relative;
        top: movanoResize(8);
        border-right: movanoResize(2) solid var(--main-blue-semiTrns) !important;

        td {
          margin: 0px !important;
          position: relative;
          bottom: movanoResize(9);
        }
      }

      tr:first-child {
        margin-left: movanoResize(10) !important;
      }

      tr:last-child {
        border: none !important;
        //width: movanoResize(30) !important;
        margin-right: 0px !important;
      }
    }
  }

  .chartToolTipDiv_UserData {
    font-family: 'Zen Kaku Gothic Antique', sans-serif !important;
    box-shadow: 0px 0px movanoResize(20) rgba(5, 66, 128, 0.15) !important;
    background: white;
    font-style: normal;
    font-weight: 700;
    font-size: movanoResize(20);
    border-radius: movanoResize(5) !important;
    border: none !important;
    width: movanoResize(137);
    z-index: 99;

    .chartToolTip_UserData {
      font-weight: 500;
      font-size: movanoResize(12) !important;
      color: #01B277 !important;
    }

    tbody {
      position: relative;
      display: flex !important;
      flex-direction: column !important;

      td {
        font-weight: 700;
        font-size: movanoResize(20);
        position: relative;
        bottom: movanoResize(7);
      }

      tr:first-child {
        filter: brightness(200%);
      }
    }
  }
}

.Activity {
  tbody {
    tr {
      color: #00B277 !important;
    }

    tr:last-child {
      color: #012E1E !important;
    }
  }
}

.Activity_Dark {
  tbody {
    tr {
      color: #00B277 !important;
    }

    tr:last-child {
      color: #FFFFFF !important;
    }
  }
}


.Sales {
  tbody {
    tr {
      color: #0B83FE !important;
    }

    tr:last-child {
      color: #012E1E !important;
    }
  }
}

.Sales_Dark {
  tbody {
    tr {
      color: #0B83FE !important;
    }

    tr:last-child {
      color: #FFFFFF !important;
    }
  }
}


.Incidents {
  tbody {
    tr {
      border-right: 0px !important;
      color: var(--main-magenta-dark) !important;
    }

    tr:last-child {
      display: none !important;
      color: #012E1E !important;
    }
  }
}

.Incidents_Dark {
  tbody {
    tr {
      border-right: 0px !important;
      color: #FEFDFE !important;
    }

    tr:last-child {
      display: none !important;
      color: #FFFFFF !important;
    }
  }
}

.movano_switch {
  display: flex;
  align-items: center;
}

.movanoHeader {
  display: flex;
  justify-content: space-between;
  width:100vw;
  height: movanoResize(53);
  align-items: center;
  padding-right: movanoResize(26);
  padding-bottom: movanoResize(5);
  background: linear-gradient(90deg, #054280 0%, #0B84FF 100%);
  box-sizing: border-box;
    //position: relative;
  //right: movanoResize(28);

  &_pauseButton {
    display: flex;
    align-items: center;

    svg {
      width: movanoResize(22);
      height: movanoResize(22);
    }
  }

  &_Functions {
    display: flex;
    gap: movanoResize(12); //calc((movanoResize(173) * 100) / 1440);
    align-items: center;

    &_Icons {
      position: relative;
      margin-right: calc(movanoResize(215) + movanoResize(12));
    }

    &_Icon {
      width: movanoResize(11);
      height: movanoResize(17);
    }

    mat-form-field {
      mat-icon {
        position: absolute;
        top: -5%;
        padding: movanoResize(12) !important; //calc((movanoResize(173) * 100) / 1440) !important;
      }
      img {
        position: absolute;
        top: 19%;
        //padding: movanoResize(12) !important; //calc((movanoResize(173) * 100) / 1440) !important;
        height: movanoResize(14);
      }
    }
  }

  &_Logo{
    display: flex;
    align-items: baseline;
    gap: movanoResize(12);
    position: absolute;
    width: 30vw;
    left: 35vw;
    justify-content: center;
    &_IMG {
      width: movanoResize(150);
      height: movanoResize(23);
    }
  }

  &_Tittle {
    display: flex;
    gap: movanoResize(12);
    //height: movanoResize(23);
    display: flex;
    align-items: baseline;

    &_Search {
      position: relative;

      .p-inputtext {
        padding-left: movanoResize(36) !important;
        padding-right: movanoResize(36) !important;
        box-shadow: 0px movanoResize(5) movanoResize(15) rgba(0, 56, 108, 0.1);
        border-radius: movanoResize(2);
        border: movanoResize(1) solid var(--main-denim-semiTrns);
        font-weight: 400;
        font-size: movanoResize(10);
        font-family: 'Zen Kaku Gothic Antique';
        color: rgba(5, 66, 128, 0.5);
      }

      svg {
        position: absolute;
      }

      svg:first-child {
        top: movanoResize(10);
        left: movanoResize(10);
        width: movanoResize(14);
        height: movanoResize(14);
        z-index: 999;
      }

      svg:last-child {
        top: movanoResize(12);
        right: movanoResize(12);
        width: movanoResize(11);
        height: movanoResize(11);
        z-index: 999;
      }

      svg:hover {
        cursor: pointer;
      }
    }

    &_btn:hover {
      cursor: pointer;
    }

    &_txt {
      font-weight: 400;
      font-size: movanoResize(30);
      color: white;
      line-height: 100%;
    }

    button {
      background: none;
      border: none;
    }

    button:hover {
      cursor: pointer;
    }
  }

  &_Version {
    font-weight: 500;
    font-size: movanoResize(12);
    opacity: 0.5;
    color: var(--main-blue-dark);
    position: relative;
    bottom: movanoResize(4);
  }

  mat-form-field {
    width: movanoResize(215);
    // height: calc(2.57vw + 20.14vw);
    //transform: scale(2);
    position: relative;
    //right: movanoResize(46);
    top: movanoResize(8);
  }

  &_userIcon {
    background-color: white;
    border-radius: movanoResize(5);
   // margin-left: movanoResize(230);
    width: fit-content;
    height: movanoResize(37);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      padding: movanoResize(10);
      width: fit-content;
      height: fit-content;


      path {
        fill: var(--main-blue-dark);
      }
    }
  }

  &_userIcon:hover {
    cursor: pointer;
    opacity: 0.75;
  }
}

.movanoTable {
  &_Header {
    border-radius: movanoResize(10);

    th {
      font-family: 'Zen Kaku Gothic Antique';
      background-color: #FFFFFF !important;
      font-weight: 700 !important;
      font-size: movanoResize(16);
      color: var(--main-blue-medium) !important;
    }

    th:first-child {
      padding-left: movanoResize(38) !important;
      border-top-left-radius: movanoResize(10);
    }

    th:last-child {
      border-top-right-radius: movanoResize(10);
    }
  }

  &_Obj {
    td:first-child {
      padding-left: movanoResize(38) !important;
      font-weight: 700;
      font-size: movanoResize(14);
    }

    td {
      font-weight: 400;
      font-size: movanoResize(14);
      color: var(--main-blue-dark);
      font-size: movanoResize(14);

    }

    &_Button {
      width: movanoResize(7);
      height: movanoResize(6);
    }

    &_Button:hover {
      cursor: pointer;
    }

    &_Badge {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: movanoResize(12);
      border-radius: movanoResize(2);
      width: max-content;
      height: max-content;
      // min-width: movanoResize(19);
      // min-height: movanoResize(19);
      padding: movanoResize(4) movanoResize(6) movanoResize(4) movanoResize(6);
      border-radius: movanoResize(2);
      line-height: 100%;
      color: var(--main-blue-dark);
      background: #c5dffa;

      &_Female {
        color: var(--main-magenta-medium);
        background: #efcfe0;
      }

      &_Blocked {
        color: #66006a;
        background: #efcfe0;
      }

      &_Inactive {
        color: rgba(119, 151, 184, 1);
        background: rgba(119, 151, 184, 0.2);
      }

      &_NoBinary {
        color: #62189a;
        background: #bc9dd4;
      }
      &_green{
        background-color: rgba(0, 179, 119, 0.2);
        color: #00B377;
      }
      &_greenDark{
        background-color: rgba(0, 178, 119, 0.2);
        color: var(--main-green-dark);
      }
      &_orange{
        background-color: rgba(255, 131, 13, 0.2);
        color: var(--main-orange);
      }

      &_orangeDark{
        background-color: rgba(255, 131, 13, 0.2);
        color: var(--main-blue-dark);

      }
      &_blue{
        background-color: rgba(11, 132, 255, 0.2);
        color: var(--main-blue-medium);
      }
      &_blueDark{
        background-color: rgba(11, 132, 255, 0.2);
        color: var(--main-blue-dark);
      }
      &_gray{
        background-color: rgba(40, 71, 103, 0.2);
        color: var(--main-denim-light);
      }
      &_grayMix{
        background-color: rgba(40, 71, 103, 0.2);
        color: var(--main-magenta-light);

      }
      &_red{
        background: rgba(227, 20, 130, 0.2);
        color: var( --main-magenta-medium);
      }
      &_Deleted{
        background: #e9c4c6;
        color: #c50000;
      }

    }
  }

  &_userDevice {
    &_Header {
      td {
        font-weight: 700;
        font-size: movanoResize(14);
        color: var(--main-blue-dark);
      }
    }

    &_Body {
      td {
        font-weight: 400;
        font-size: movanoResize(14);
        color: var(--main-blue-dark);
      }
    }
  }

  &_click:hover{
    cursor: pointer;
  }
  &_Inside{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-left: movanoResize(32);
    padding-right: movanoResize(64);
  }
}
.movanoPaginatorAmont{
  position: absolute;
  bottom: movanoResize(10);
  right: movanoResize(20);
  color: var(--main-blue-dark);
  font-size: movanoResize(12);
  opacity: 0.5;
  font-weight: 500;
}


.movanoPaginator {
  display: flex;
  gap: movanoResize(18);
  align-items: center;
  // width: 93.6vw;
  // justify-content: center;

  svg:hover {
    cursor: pointer;
  }

  svg:first-child {
    width: movanoResize(12);
    height: movanoResize(12);
  }

  svg {
    width: movanoResize(18);
    height: movanoResize(8);
  }

  svg:last-child {
    width: movanoResize(12);
    height: movanoResize(12);
  }

  td:hover {
    cursor: pointer;
  }

  button:hover {
    cursor: pointer;
  }

  &_PageSlcFirst {
    font-weight: 700;
    font-size: movanoResize(14);
    color: var(--main-blue-medium);
  }

  &_PageSlc {
    font-weight: 400;
    font-size: movanoResize(13);
    color: var(--main-blue-dark)
  }
}

.movanoLeftMenu {
  background: #FFFFFF;
  width: movanoResize(327);
  overflow-y: hidden;

  &_Header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: movanoResize(34);
    color: var(--main-denim-light);
    font-size: movanoResize(30);
    padding: movanoResize(23) movanoResize(38) movanoResize(23) movanoResize(38);
    position: relative;

    svg:hover {
      cursor: pointer;
    }

    svg {
      width: movanoResize(12);
      height: movanoResize(12);
    }
  }

  &_Button {
    display: grid;
    align-items: center;
    height: movanoResize(65);
    grid-template-columns: movanoResize(50) calc(100% - movanoResize(80)) movanoResize(28);
    justify-content: baseline;
    padding: 0px movanoResize(38) 0px movanoResize(38);
    font-weight: 400;

    &_Txt {
      display: flex;
      color: var(--main-blue-dark);
      font-size: movanoResize(18);
      justify-self: baseline;
      line-height: 100%;
      margin-bottom: movanoResize(4);
      width: 100%;
    }

    &_Tittle {
      width: fit-content;
      height: fit-content;
      display: flex;
      align-items: center;

      &_Icon01 {
        width: movanoResize(23);
        height: movanoResize(23);
      }

      &_Icon02 {
        width: movanoResize(22);
        height: movanoResize(22);
      }

      &_Icon03 {
        width: movanoResize(14);
        height: movanoResize(22);
      }

      &_Icon04 {
        width: movanoResize(20);
        height: movanoResize(17);
      }
      &_Icon05{
        width: movanoResize(19);
        height: movanoResize(19);
      }
      &_Icon06{
        width: movanoResize(17);
        height:movanoResize(17);
      }
      &_Icon07{
        width: movanoResize(20);
        height:movanoResize(20);
      }
      &_Icon08{
        width: movanoResize(18);
        height:movanoResize(18);
      }
    }
  }

  &_Button:hover {
    background: rgba(196, 217, 238, 0.2);
    font-weight: 900;
    cursor: pointer;
  }

  // &_Filter {
  //   display: flex;
  //   flex-direction: column;
  //   padding: 0px movanoResize(38) movanoResize(27) movanoResize(38);

  //   &_Tittle {
  //     font-weight: 500;
  //     font-size: movanoResize(14);
  //     //line-height: movanoResize(145);
  //     color: var(--main-blue-dark);
  //     margin-bottom: movanoResize(14);
  //   }

  //   &_checkBox {
  //     display: grid;
  //     grid-template-columns: auto auto;

  //     span {
  //       color: var(--main-blue-dark);
  //       font-size: movanoResize(12);
  //     }
  //   }
  // }

}

.movanoFilterMenu {
  background: #FFFFFF;
  width: movanoResize(327);
  height: 95vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: movanoResize(35);
  gap: movanoResize(20);

  &_Header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    //margin-bottom: movanoResize(34);
    color: var(--main-denim-light);
    font-size: movanoResize(30);
    padding: movanoResize(23) movanoResize(38) movanoResize(23) movanoResize(38);
    position: relative;

    a {
      margin-left: movanoResize(80);
    }

    svg:hover {
      cursor: pointer;
    }

    svg {
      width: movanoResize(12);
      height: movanoResize(12);
    }
  }

  &_Button {
    display: grid;
    align-items: center;
    height: movanoResize(65);
    grid-template-columns: movanoResize(50) calc(100% - movanoResize(80)) movanoResize(28);
    justify-content: baseline;
    padding: 0px movanoResize(38) 0px movanoResize(38);
    font-weight: 400;

    &_Txt {
      display: flex;
      color: var(--main-blue-dark);
      font-size: movanoResize(18);
      justify-self: baseline;
      line-height: 100%;
      margin-bottom: movanoResize(4);
      width: 100%;
    }

    &_Tittle {
      width: fit-content;
      height: fit-content;
      display: flex;
      align-items: center;

      &_Icon01 {
        width: movanoResize(23);
        height: movanoResize(23);
      }

      &_Icon02 {
        width: movanoResize(22);
        height: movanoResize(22);
      }

      &_Icon03 {
        width: movanoResize(14);
        height: movanoResize(22);
      }

      &_Icon04 {
        width: movanoResize(20);
        height: movanoResize(17);
      }
    }
  }

  &_Button:hover {
    background: rgba(196, 217, 238, 0.2);
    font-weight: 900;
    cursor: pointer;
  }

  &_Filter {
    display: flex;
    flex-direction: column;
    padding: 0px movanoResize(38) 0px movanoResize(38);


    &_Tittle {
      font-weight: 500;
      font-size: movanoResize(14);
      //line-height: movanoResize(145);
      color: var(--main-blue-dark);
      margin-bottom: movanoResize(14);
    }

    &_checkBox {
      display: grid;
      grid-template-columns: auto auto;

      span {
        color: var(--main-blue-dark);
        font-size: movanoResize(12);
      }
    }
  }

  .mat-mdc-select-placeholder {
    margin-left: 0px !important;
  }
}

.movanoTriangleInput {
  transform: rotate(0deg);
  position: absolute;
  bottom: 42%;
  right: 0.84vw;
  width: 0.555vw;
  height: 0.4166vw;

}

.mat-mdc-checkbox .mdc-checkbox {
  // padding: 0px movanoResize(8) 0px movanoResize(8) !important;
}

.movanoUserMenu {
  background: white;
  //height: 100vh;
  width: 100%;
  color: var(--main-blue-dark);

  .movano_switch_icon {
    width: movanoResize(11) !important;
    height: movanoResize(17) !important;

    input {
      margin: 0px !important;
    }
  }

  &_Header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: movanoResize(34);
    font-weight: 700;
    font-size: movanoResize(12);


    svg:hover {
      cursor: pointer;
    }

    svg {
      width: movanoResize(12);
      height: movanoResize(12);
    }
  }

  &_userIMG {
    display: flex;
    gap: movanoResize(20);
    margin-bottom: movanoResize(28);

    &_Txt {
      display: flex;
      flex-direction: column;

      span:first-child {
        font-weight: 900;
        font-size: movanoResize(28);
      }

      span:last-child {
        font-weight: 400;
        font-size: movanoResize(18);
      }
    }

    &_userIcon {
      background-color: var(--main-denim-semiTrns);
      border-radius: movanoResize(5);
      width: fit-content;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        // width: 2.22vw;
        // height: 2.36vw;
        padding: movanoResize(16);
        width: fit-content;
        height: fit-content;

        path {
          fill: var(--main-blue-dark);
        }
      }
    }
  }

  &_Log {
    display: flex;
    flex-direction: column;
    gap: movanoResize(22);
    align-items: center;

    span {
      color: black;
      font-weight: 700;
      font-size: movanoResize(12);
    }

    span:hover {
      cursor: pointer;
    }
  }

  app-movano-button {
    span {
      color: white;

    }
  }
}



.p-datatable .p-datatable-tbody>tr>td {
  border: 0px;
  max-width: 12vw;
  word-wrap: break-word;
  max-height: fit-content;

  svg {
    width: movanoResize(18);
  }

  svg:hover {
    width: movanoResize(18);
  }
}

.movanoTableBox {
  //background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 75%);
  border-radius: 0px movanoResize(10) movanoResize(10) 0px;
  width: calc(100vw - movanoResize(56));
  overflow-x: hidden;
  height: fit-content;
  padding-bottom: 15px;
  &_mobileShadow{
    display: none;
    -webkit-box-shadow: inset -71px 0px 23px -42px rgb(255, 255, 255);
    -moz-box-shadow: inset -71px 0px 23px -42px rgb(255, 255, 255);
    position: absolute;
    box-shadow: inset -71px 0px 23px -42px rgb(255, 255, 255);
    height: calc(100% - 54px);
    width: 55px;
    right: 0;
    top: 0;
    &_Left{
      right: unset;
      left: 0;
      box-shadow: inset 71px 0px 23px -42px rgb(255, 255, 255);
      -webkit-box-shadow: inset 71px 0px 23px -42px rgb(255, 255, 255);
      -moz-box-shadow: inset 71px 0px 23px -42px rgb(255, 255, 255);
    }
  }
}

.menu-left.p-sidebar {
  background: #FFFFFF;
  width: fit-content;
  box-shadow: 0px 0px movanoResize(80) rgba(0, 70, 135, 0.2);
 // z-index: auto !important;
  overflow: hidden;

  .p-sidebar-right {
    width: fit-content;
  }

  .p-sidebar-header {
    display: none;
  }

  .p-sidebar-content {
    padding: 0px;
    overflow: hidden;
  }
}

.menu-filter.p-sidebar {
  background: #FFFFFF;
  width: fit-content;
  box-shadow: 0px 0px movanoResize(80) rgba(0, 70, 135, 0.2);
  //z-index: auto !important;
  overflow: hidden;

  .p-sidebar-right {
    width: fit-content;
  }

  .p-sidebar-header {
    display: none;
  }

  .p-sidebar-content {
    padding: 0px;
    overflow: hidden;
  }
}

.user-menu.p-sidebar {
  background:
    url("../src/assets/VectorUpSideLogged.png") movanoResize(66) calc(100vh - movanoResize(286)) no-repeat,
    url("../src/assets/MovanoSmallIcon.png") movanoResize(240) calc(95vh - movanoResize(57)) no-repeat,
    white;
  background-size: movanoResize(281) movanoResize(286), movanoResize(64) movanoResize(57);
  color: #FFFFFF;
  width: fit-content;

  .p-sidebar-right {
    width: fit-content;
  }

  .p-sidebar-header {
    display: none;
  }

  .p-sidebar-content {
    padding: movanoResize(23) movanoResize(38) movanoResize(23) movanoResize(38);
  }
}

.user-menu.p-sidebar:has(.movanoUserMenu_Dark) {
  background:
    url("../src/assets/VectorUpSideLogged.png") movanoResize(66) calc(100vh - movanoResize(286)) no-repeat,
    url("../src/assets/MovanoSmallIcon.png") movanoResize(240) calc(95vh - movanoResize(57)) no-repeat,
    var(--main-blue-dark);
  background-size: movanoResize(281) movanoResize(286), movanoResize(64) movanoResize(57);
}

.p-datatable .p-paginator-bottom {
  border: unset;
  border-radius: 0px 0px movanoResize(10) movanoResize(10);
}

.p-paginator .p-paginator-first {
  display: none;
}

.p-paginator .p-paginator-prev {
  display: none;
}

.p-paginator .p-paginator-next {
  display: none;
}

.p-paginator .p-paginator-last {
  display: none;
}

.p-paginator-pages {
  display: none;
}

.p-paginator-right-content {
  margin-right: auto;
}

.movanoInputSearch {
  padding-left: movanoResize(36) !important;
  padding-right: movanoResize(36) !important;
  box-shadow: 0px movanoResize(5) movanoResize(15) rgba(0, 56, 108, 0.1);
  border-radius: movanoResize(2);
  border: movanoResize(1) solid var(--main-denim-semiTrns);
  font-weight: 400;
  font-size: movanoResize(10);
  font-family: 'Zen Kaku Gothic Antique';
  color: rgba(5, 66, 128, 0.5);
}

.movanoCalendar {
  position: relative;

  mat-icon {
    //z-index: 999;
    height: movanoResize(28) !important;
    width: movanoResize(28) !important;
    position: absolute;
    top: -5%;
    padding: movanoResize(12) !important;
  }

  svg {
    transform: rotate(0deg);
    position: absolute;
    bottom: 42%;
    right: movanoResize(12);
    width: movanoResize(7);
    height: movanoResize(6);
  }
}
.movano-Table .p-datatable-tbody > tr > td,
.movano-Table .p-datatable-tbody > tr > th,
.movano-Table .p-datatable-thead > tr > th {
  border-width: 0; /* Oculta las líneas separadoras */
  border: unset;
}
.menu-left.p-sidebar {
  background:
    url("../src/assets/VectorDesktop.png") 0px calc(100vh - movanoResize(340)) no-repeat,
    white;
  background-size: movanoResize(281) movanoResize(286);
}

.menu-left.p-sidebar:has(.movanoDark) {
  background:
    url("../src/assets/VectorDesktop.png") 0px calc(100vh - movanoResize(340)) no-repeat,
    var(--main-blue-dark);
  background-size: movanoResize(281) movanoResize(286);
}

.menu-filter.p-sidebar{
  background: white;
}
.menu-filter.p-sidebar:has(.movanoDark){
  background: var(--main-blue-dark);
}
//DARK MODE
.movanoDark {
  background-color: #0c1d35 !important;
  color: white !important;
  .movanoPaginatorAmont{
    color: white;
    opacity: 1;
  }
  .movanoHeader {
    &_Tittle {
      svg:first-child {
        path {
          stroke: var(--main-blue-semiTrns);
        }
      }

      svg:last-child {
        rect {
          fill: var(--main-blue-light);
        }

        path {
          fill: var(--main-blue-light) !important;
          stroke: var(--main-blue-light);
        }
      }

      &_txt {
        color: white;
      }
    }

    &_Version {
      color: white;
    }

    &_userIcon {
      background-color: var(--main-blue-dark);

      svg>path {
        fill: white;
      }
    }
  }
  .chartToolTipDiv_UserData{
    background: #05305B !important;
  }
  .movanoTable {
    &_Obj {
      td {
        color: white;
      }

      &_Badge {
        background-color: var(--main-blue-medium);
        color: white;

        &_Female {
          background: var(--main-magenta-light);
        }

        &_Blocked {
          background: var(--main-magenta-dark);
        }

        &_Inactive {
          background: var(--main-denim-dark);
        }

        &_NoBinary {
          background: #bc9dd4;
        }
      }
    }

    &_Header th {
      background-color: #05305B !important;
      color: var(--main-blue-medium) !important;
    }
    &_userDevice {
      &_Header {
        td {
          color: white;
        }
      }

      &_Body {
        td {
          color: white;

        }
      }
    }
  }

  .movanoTableBox_mobileShadow{
    -webkit-box-shadow: inset -71px 0px 23px -42px rgb(16 40 74);
    -moz-box-shadow: inset -71px 0px 23px -42px rgb(16 40 74);
    box-shadow: inset -71px 0px 23px -42px rgb(16 40 74);
    &_Left{
      -webkit-box-shadow: inset 71px 0px 23px -42px rgb(16 40 74);
      -moz-box-shadow: inset 71px 0px 23px -42px rgb(16 40 74);
      box-shadow: inset 71px 0px 23px -42px rgb(16 40 74);
    }
  }

  .movanoInputSearch {
    color: white;
    background: rgba($color: #FFF, $alpha: 0.1);
    border: none;
  }

  .movanoLeftMenu {
    background: var(--main-blue-dark);

    &_Header {
      color: #FFFFFF;

      svg:hover {
        cursor: pointer;
      }
    }

    &_Button {

      &_Txt {
        color: #FFFFFF;
      }

      &_Tittle {
        width: fit-content;
        height: fit-content;
        display: flex;
        align-items: center;

        &_Icon01 {
          g>path {
            fill: white !important;
          }
        }

        &_Icon02 {
          g>path {
            fill: var(--main-blue-light) !important;
          }
        }

        &_Icon03 {
          path {
            fill: white !important;
          }
        }

        &_Icon04 {
          path {
            fill: white !important;
          }
        }
      }
    }

    &_Button:hover {
      background: rgba(196, 217, 238, 0.2);
      font-weight: 900;
      cursor: pointer;
    }

    &_Filter {
      display: flex;
      flex-direction: column;
      padding: 0px movanoResize(38)movanoResize(37) movanoResize(38);

      &_Tittle {
        font-weight: 500;
        font-size: movanoResize(14);
        //line-height: movanoResize(145);
        color: var(--main-blue-dark);
        margin-bottom: movanoResize(14);
      }

      &_checkBox {
        display: grid;
        grid-template-columns: auto auto;

        span {
          color: var(--main-blue-dark);
          font-size: movanoResize(12);
        }
      }
    }

  }

  .movanoFilterMenu {
    background: var(--main-blue-dark);

    &_Header {
      color: #FFFFFF;

      svg:hover {
        cursor: pointer;
      }
    }

    &_Button {

      &_Txt {
        color: #FFFFFF;
      }
    }

    &_Button:hover {
      background: rgba(196, 217, 238, 0.2);
    }

    &_Filter {
      display: flex;
      flex-direction: column;

      &_Tittle {
        color: White;
      }

      &_checkBox {

        span {
          color: white;
        }
      }
    }
    .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background{
      background-color: var(--main-blue-medium)  !important;
    }
    .mdc-checkbox__background{
      border: unset !important;
      background-color: rgba(255, 255, 255, 0.1) !important;
    }
    .movanoCalendar {
      svg>path {
        fill: white;
      }
    }

    .mat-mdc-text-field-wrapper {
      background-color: transparent !important;
      border: none;
    }

    .mat-mdc-form-field-focus-overlay {
      background-color: rgba(255, 255, 255, 0.1) !important;
    }

    .p-calendar .p-inputtext {
      background: rgba(255, 255, 255, 0.1);
      border: none;
      color: white;
    }

  }
  .mat-mdc-option {
    span {
      color: #FFFFFF !important;
    }
  }

  .p-datatable .p-paginator-bottom {
    background-color: #05305B;
  }

  .movanoPaginator_PageSlc {
    color: white;
  }

  .mat-mdc-select-panel {
    background: rgb(32, 52, 75) !important;
  }

  .mat-mdc-select-placeholder {
    color: #FFFFFF !important;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #FFFFFF !important;
    /* Firefox */
    opacity: 1;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #FFFFFF !important;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #FFFFFF !important;
  }

  .mat-mdc-form-field-infix {

    span {
      color: #FFFFFF;
    }
  }


  .mat-mdc-select-placeholder {
    color: #FFFFFF;
  }

  .mat-mdc-select-arrow-wrapper {
    display: none !important;
  }

  .mat-mdc-text-field-wrapper {
    border: movanoResize(1) solid var(--main-denim-dark);
    background: black;

    box-shadow: 0px 0px movanoResize(10) rgba(0, 56, 108, 0.3);

    svg {
      transform: rotate(-0deg) !important;
    }

    path {
      fill: #FFFFFF !important;
    }
  }

  .mdc-line-ripple {
    display: none !important;
  }

  .mat-mdc-text-field-wrapper:hover {
    border: movanoResize(1) solid var(--main-blue-medium);
    background: #FFF;
  }

  .mdc-text-field--focused {
    border: movanoResize(1) solid var(--main-blue-medium) !important;

    svg {
      transform: rotate(-180deg) !important;
    }

    path {
      fill: #AABED3 !important;
    }
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: rgb(32, 52, 75) !important;
  }
}

.movanoUserMenu_Dark {
  background: var(--main-blue-dark);
  color: white;

  .movanoUserMenu {
    background: var(--main-blue-dark);

    &_userIMG {

      &_userIcon {
        background-color: var(--main-denim-dark);

        svg {
          path {
            fill: #FFFFFF;
          }
        }
      }
    }

    &_Log {
      span {
        color: white;
      }
    }
  }
}

//ANIMATIONS

.scale-up-hor-left {
  -webkit-animation: scale-up-hor-left 0.25s cubic-bezier(0.390, 0.575, 0.565, 1.000);
  animation: scale-up-hor-left 0.25s cubic-bezier(0.390, 0.575, 0.565, 1.000);
}

/* ----------------------------------------------
 * Generated by Animista on 2023-4-3 9:58:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-hor-left
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-hor-left {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}

@keyframes scale-up-hor-left {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}

.scale-in-ver-top {
  -webkit-animation: scale-in-ver-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in-ver-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-4-3 16:14:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-ver-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

.movanoHeaderMovil {
  display: none;
}
.UtcList.mdc-menu-surface.mat-mdc-select-panel {
  max-height: 205px !important;
}
.mdc-menu-surface.mat-mdc-select-panel {
  max-height: max-content !important;
}

.mat-mdc-option:has(.movanoMap_Body_States_State) {
  height: fit-content !important;
}

/* For ascending order, use pi-arrow-up */
.ui-table .ui-sortable-column .ui-sortable-column-icon.pi-chevron-up::before {
  content: "\f077"; /* This is the unicode for pi-arrow-up */
}

/* For descending order, use pi-arrow-down */
.ui-table .ui-sortable-column .ui-sortable-column-icon.pi-chevron-down::before {
  content: "\f078"; /* This is the unicode for pi-arrow-down */
}

/* For unsorted column, use pi-arrow-down */
.ui-table .ui-sortable-column .ui-sortable-column-icon.pi-chevron-up-down::before {
  content: "\f078"; /* This is the unicode for pi-arrow-down */
}



.movanoMap {

  .mat-mdc-form-field-infix {
    span {
      line-height: movanoResize(30);
    }
  }

  mat-form-field {
    width: movanoResize(160) !important;
  }

  .mat-mdc-option {
    span {
      color: inherit !important;
    }
  }

  .mat-mdc-select-placeholder {
    margin-left: 0px !important;
  }
}

.movanoHeader_Tittle_btn:first-child {
  // width: movanoResize(20);
  // height: movanoResize(16);
  margin-left: 0px;
}

@media (max-width: 1025px) {
  .movanoHeader {
    width: 100vw;
    right: movanoResize(18);

    &_Version {

      display: none;
    }
    &_Logo{
      &_IMG {
        width: 34px;
        height: 29px;
        object-fit: cover;
        object-position: left;
      }
    }
    &_Tittle {


      &_btn:first-child {
        width: 18px;
        height: 18px;
        margin-left: 0px;
      }

      &_btn {
        width: 14px;
        height: 14px;
        margin-left: 25px;
      }

      &_btn:last-child {
        width: 13px;
        height: 13px;
        margin-left: 10px;
      }

      &_txt {
        font-weight: 400;
        font-size: 30px;
      }
    }
  }

  .movanoTable_Header th {
    font-size: movanoResize(16);
  }

  .movanoUsers {
    padding: 0px movanoResize(18) movanoResize(20) movanoResize(18);
  }

  .movanoTableBox {
    width: calc(100vw - movanoResize(36));
  }
}

@media (max-width : 710px){
  .movanoTableBox{
    position: relative;
    &_mobileShadow{
      display: block;
      &_Left{
        display: none;
      }
    }
  }
}

@media (max-width: 426px) {
  .movanoPaginatorAmont{
    position: unset;
    display: block;
    width: 100%;
    text-align: center;
  }
  .mat-mdc-option {
    span {
      font-size: movanoResize(11) !important;
    }
  }

  .mat-mdc-form-field-infix {
    span {
      margin-left: 0px;
    }
  }

  .movanoLeftMenu {
    width: 100vw;
  }

  .movanoFilterMenu {
    width: 100vw;
  }

  .movanoHeader_Tittle_txt {
    font-size: 24px;
    color:var(--main-denim-light);
  }

  .user-menu.p-sidebar {
    width: 100vw;
  }

  .mat-mdc-form-field-bottom-align {
    display: none;
  }

  .movanoInputSearch {
    width: 45vw !important;
  }

  .movanoTableBox {
    margin: 0px 12px 0px 12px;
    border-radius: 10px;
    position: relative;
    width: calc(100vw - max(calc(36 * (100vw/1440)), 24px));
  }

  .movanoHeader {
    display: none;
  }

  .p-datatable .p-datatable-tbody>tr>td {
    border: 0px;
    max-width: 100vw;
    word-wrap: break-word;
  }

  .movanoHeaderMovil {
    display: block;
    //width: 100vw;
    .movanoHeader_Logo{
      &_IMG {
        width: 100px;
        height: 20px;
        object-fit: cover;
        object-position: right;
      }
    }
    .movanoHeader_Tittle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: inherit;
      //padding-right: 20px;
      padding-top: 10px;

    }

    .movanoHeader_Tittle:first-child {
      border-bottom: 1px solid var(--main-denim-semiTrns);
      padding-bottom: 10px;
    }

    .movanoHeader_Tittle:last-child {
      //padding-left: 16px;
      padding-bottom: 15px;
    }

    .movanoHeader_userIcon {
      margin-left: 0px;
    }
  }

  .movanoUserPanel .p-inputtext {
    width: movanoResize(115) !important;
    border: 0px !important;
    box-shadow: none !important;
  }
  .movanoUserPanel.p-inputtext:not(.p-dropdown-label.p-placeholder) {
    padding: 1px !important;
  }
  .movanoCalendar {
    position: relative;

    mat-icon {
      //z-index: 999;
      display: none;
      height: movanoResize(28) !important;
      width: movanoResize(28) !important;
      position: absolute;
      top: -5%;
      padding: movanoResize(12) !important;
    }

    svg {}
  }

  .mat-mdc-select-placeholder {
    margin-left: 0px !important;
    color: var(--main-blue-dark) !important;
    font-weight: 500 !important;
  }

  .mat-mdc-text-field-wrapper {
    border: none;
    box-shadow: none;
    background: none !important;
  }

  .mat-mdc-form-field-focus-overlay {
    background: rgba(0, 0, 0, 0) !important;
  }

  mat-form-field {
    width: movanoResize(115) !important;

    mat-icon {
      display: none;
    }
  }
}
